.wrapperClassName {
  background-color: #ffffff;
  border: 0px solid #ffffff;
  width: 118.2px;
  height: 38px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

.calendarClassName {
  background-color: #ffffff;
  border: 0px solid #ffffff;
  padding: 0px;
  margin: 0px;
}
