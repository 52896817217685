@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Rubik:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif !important;
  font-weight: 300 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.font-family-1 {
  font-family: 'Rubik', sans-serif;
}
.font-family-2 {
  font-family: 'Poppins', sans-serif;
}

.hide_scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}
